import { completedCelestialObjects } from '../scene-config';
const filterObjectsByname = (name) => {
    let isAdditional;
    const isCelestial = completedCelestialObjects.find((object) => {
        if (isAdditional === undefined) {
            isAdditional = object === null || object === void 0 ? void 0 : object.childObjects.find((element) => element.name === name);
        }
        return object.name === name ? name : false;
    });
    let result = {
        name: '',
        distanceFromPrimary: 0,
        radius: 0,
        classification: 'Star',
        theta: 0,
        dTheta: 0
    };
    if (isCelestial === undefined && isAdditional !== undefined) {
        result = isAdditional;
    }
    if (isAdditional === undefined && isCelestial !== undefined) {
        result = isCelestial;
    }
    return result;
};
export const getMaxSize = (name, isSolarSystem) => {
    const totalBuffer = 1.3;
    const singleObjectBuffer = 3;
    const filteredObjects = filterObjectsByname(name);
    let maxDistance = 0;
    if ('childObjects' in filteredObjects &&
        filteredObjects.childObjects.length > 0) {
        const distances = filteredObjects.childObjects.map((value) => value.distanceFromPrimary);
        maxDistance = Math.max.apply(null, distances);
    }
    else {
        maxDistance = filteredObjects.radius * singleObjectBuffer;
    }
    if (isSolarSystem) {
        const lastIndex = completedCelestialObjects.length - 1;
        maxDistance = completedCelestialObjects[lastIndex].distanceFromPrimary;
    }
    const sceneSize = maxDistance * totalBuffer;
    return sceneSize;
};
export const getMinSize = (name) => {
    const buffer = 1.25;
    const filteredObjects = filterObjectsByname(name);
    const minSize = filteredObjects.radius * buffer;
    return minSize;
};
