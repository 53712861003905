import { Object3D } from 'three';
import scalingFactor from './scaling';
import { loadModel } from './loaders';
import { objectsToLoad, loadedObjects, additionalLoadingComplete, showAdditionalLoader } from '../store';
const showLoader = () => {
    additionalLoadingComplete.set(false);
    showAdditionalLoader.set(true);
};
const setObjectsToLoad = (childObjects) => {
    showLoader();
    objectsToLoad.set(childObjects.flatMap((childObject) => {
        let completedLoad = [];
        completedLoad = [...completedLoad, childObject.name];
        if ('childObjects' in childObject) {
            childObject.childObjects.forEach((additionalObject) => {
                completedLoad = [...completedLoad, additionalObject.name];
            });
        }
        return completedLoad;
    }));
};
const incrementLoadedObjects = () => {
    loadedObjects.update((val) => val + 1);
};
const hideLoader = () => {
    const transitionDuation = 1500;
    additionalLoadingComplete.set(true);
    setTimeout(() => {
        loadedObjects.set(0);
        objectsToLoad.set([]);
    }, transitionDuation);
};
const generatePreparedObject = async (object, parentRadius) => {
    const model = await loadModel(object.name);
    const object3d = new Object3D();
    object3d.add(model.scene);
    object3d.scale.set(...scalingFactor(object3d, object.radius, model.scene.scale.x));
    object3d.position.setX(object.distanceFromPrimary);
    const preparedObject = {
        data: object3d,
        theta: object.theta,
        dTheta: object.dTheta,
        startX: object.distanceFromPrimary + parentRadius
    };
    incrementLoadedObjects();
    return preparedObject;
};
export default async (childObjects, parentRadius) => {
    setObjectsToLoad(childObjects);
    const preparedObjects = await Promise.all(childObjects.map(async (object) => {
        const preparedObject = generatePreparedObject(object, parentRadius);
        if ('childObjects' in object) {
            object.childObjects.map(async (additionalObject) => {
                const preparedChildObjects = generatePreparedObject(additionalObject, parentRadius);
                return preparedChildObjects;
            });
        }
        return preparedObject;
    }));
    hideLoader();
    return preparedObjects;
};
