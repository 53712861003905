import { writable } from 'svelte/store';
// TODO type errors object
export const errors = writable([]);
export const initialSceneGenerated = writable(false);
export const loadingPercent = writable(0);
export const loadingStatus = writable(true);
export const loadingMessage = writable('Downloading Textures');
export const additionalLoadingComplete = writable(true);
export const loadedObjects = writable(0);
export const objectsToLoad = writable([]);
export const showAdditionalLoader = writable(false);
export const backgroundTexture = writable();
export const showNavBar = writable(false);
